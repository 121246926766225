.slider-element {
    padding-bottom: 15vw;
}

.swiper-wrapper {
    transition-timing-function: linear !important;
}

@media screen and (max-width: 980px) {
    .slider-element {
        padding-bottom: 20vw;
    }
}

@media screen and (max-width: 640px) {
    .slider-element {
        padding-bottom: 30vw;
    }
}

@media screen and (max-width: 480px) {
    .slider-element {
        padding-bottom: 50vw;
    }
}

@keyframes slide-right {
    0% {
        left: 0;
    }
    100% {
        left: -125%;
    }
}