html {
    overflow-x: hidden;
}

.single-project-main-image {
    width: 100vw;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}
.single-project-main-image::after {
    content:'';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -0;
    background:rgba(0,0,0,.5);
}