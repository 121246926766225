.project-info {
    position: absolute;
    top: 75%;
    right: 7%;
    transition: .3s ease-in-out;
}

.project-image {
    transition: .3s ease-in-out;
}

.project-container:hover .project-info {
    opacity: 1 !important;
}
.project-container:hover .project-image{
    filter: brightness(65%);
}