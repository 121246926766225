@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
  --dark-color: #1F1F1F;
  --white-cream-color: #F1F1F1;
  --skin-color: #F1C592;
  --brown-color: #2F1D0E;
}

::selection {
  color: var(--dark-color);
  background: var(--skin-color);
}

/*Reusable classes-variables*/
.font-anton {
  font-family: 'Anton', sans-serif !important;
}

.font-antonio {
  font-family: 'Antonio', sans-serif !important;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

.text-color-light {
  color: var(--white-cream-color) !important;
}

.text-color-dark {
  color: var(--dark-color) !important;
}

.text-color-brown {
  color: var(--brown-color) !important;
}

.bg-color-light {
  background-color: var(--white-cream-color) !important;
}

.bg-color-dark {
  background-color: var(--dark-color) !important;
}

.bg-color-skin {
  background-color: var(--skin-color) !important;
}

.bg-color-brown {
  background-color: var(--brown-color) !important;
}

.text-section {
  font-size: calc(30px + 3vw);
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.title-text-shadow {
  text-shadow: 14px 14px 13px rgba(0, 0, 0, 0.25);
}

.text-responsive-1 {
  font-size: calc(15px + 1vw);
}

.bg-image-configuration {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-blur {
  backdrop-filter: blur(3px);
}

.move-from-header {
  padding-top: 6vw;
}

.h-40 {
  height: 40%;
}

.h-65 {
  height: 65%;
}

.h-90 {
  height: 90%;
}

.vh-50 {
  height: 50vh;
}

.vh-65 {
  height: 65vh;
}

.vh-85 {
  height: 85vh;
}

.mx-xl-3 {
  margin: none;
}

/*a Elements Configuration*/
a {
  text-decoration: none;
  cursor: pointer;
  transition: .1s ease-in-out;
}

/*Cookie Form*/
.cookies-form {
  height: 15vh;
  background: linear-gradient(180deg, rgba(205, 168, 114, 0.00) 0%, #D9D9D9 32.29%, #D9D9D9 100%);
}

.remove-form {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: -100%;
  left: -100%;
}

/*Responsive classes*/
@media screen and (max-width: 1400px) {
  .cookies-form {
    height: 30vh;
    background: linear-gradient(180deg, rgba(205, 168, 114, 0.00) 0%, #D9D9D9 15.29%, #D9D9D9 100%);
  }
}

@media (max-width: 1400px) and (min-width: 1200px) { 
  .mx-xl-3 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 576px) {
  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-35 {
    height: 35% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .h-lg-65 {
    height: 65% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .vh-lg-50 {
    height: 50vh;
  }

  .vh-sm-170 {
    height: 170dvh !important;
  }

  .move-from-header {
    padding-top: 15vh;
  }
}


@media screen and (max-width: 576px) {
  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-35 {
    height: 35% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-55 {
    height: 55% !important;
  }

  .h-sm-60 {
    height: 60% !important;
  }

  .h-sm-65 {
    height: 65% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-80 {
    height: 80% !important;
  }

  .h-sm-90 {
    height: 90% !important;
  }


  .h-sm-100 {
    height: 100% !important;
  }

  .vh-sm-35 {
    height: 35vh;
  }

  .vh-sm-170 {
    height: 170dvh !important;
  }

  .move-from-header {
    padding-top: 10vh;
  }

  .cookies-form {
    height: 35vh;
    background: linear-gradient(180deg, rgba(205, 168, 114, 0.00) 0%, #D9D9D9 16.29%, #D9D9D9 100%);
  }
}