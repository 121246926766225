.lg-visible {
    display: none !important;
}

/*Menu button animation*/
.menu-button {
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.menu-button::after {
    content: ' ';
    width: 500px;
    height: 2px;
    position: absolute;
    top: 120%;
    background: var(--dark-color);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .3s linear;
}

.menu-button:hover::after {
    top: 90% !important;
    opacity: 1;
}

/*Show and Hide Menu Classes*/
.show-menu {
    opacity: 1;
    left: 0 !important;
    z-index: 3 !important;
    transition: .2s ease-in-out;
}
.hide-menu {
    opacity: 0;
    left: 100% !important;
    transition: .2s ease-in-out;
}

/*Menu list*/
.nav {
    z-index: 3 !important;
}
nav, li {
    font-family: 'Euclid';
    font-size: calc(25px + 3vw);
    list-style-type: none;
}

/*Responsive*/
@media screen and (min-width: 1070px) {
    .lg-hidden {
        display: none !important;
    }

    .lg-visible {
        display: flex !important;
    }
}

@media screen and (max-width: 768px) {
    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}