section {
    min-height: 100dvh;
    position: relative;
}

/*Hero Section*/
.hero-section-image-container {
    height: 70dvh;
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) ), url('../../assets/img/about_us_hero_image.jpg');
    background-size: cover;
    background-position: 10% 10%;
    background-repeat: no-repeat;
    border-radius: 0% 0% 3vw 3vw;
}

.hero-section-big-text {
    font-size: calc(30px + 3vw);
    line-height: normal;
}

.typing-animated-text {
    position: relative;
}

.typing-animated-text::after {
    content: ' ';
    width: 5px;
    height: calc(30px + 3vw);
    background-color: #000;
    position: absolute;
    margin-left: 10px;
    margin-top: 2%;
    animation: blinking 1.5s ease-in-out infinite;
}

@keyframes blinking {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }

}

.hero-section-small-text {
    font-size: calc(2px + 1vw);
}
  
/*Section with recent project*/
.about-us-container-image-section {
    background-image: url('../../assets/img/main-page-about-us.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.title-big-text {
    font-size: calc(20px + 3vw);
}

.orange-indicator-border {
    background-color: rgba(241, 146, 35, 0.3);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: indicator-glow 2s ease-in-out infinite;
}
.orange-indicator-center {
    background-color: rgba(241, 146, 35, 0.3);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: indicator-glow 2s ease-in-out infinite;
}

@keyframes indicator-glow {
    0% {
        background-color: rgba(241, 146, 35, 0.3);
    }
    50% {
        background-color: rgba(241, 146, 35, 0.8);
    }
    100% {
        background-color: rgba(241, 146, 35, 0.3);
    }
}

/*AI Section*/
.ai-section-static-parallax-image {
    height: 65%;
    /*width: 65%;*/
    background-image: url('../../assets/img/main-page-ai-section-image.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 10% top;
    background-size: 60%;
}

/*Ripple button effect configuration*/
.ripple {
    position: relative;
    overflow: hidden;
    z-index: 2;
    transition: .3s linear;
}

.ripple:hover {
    color: var(--dark-color) !important;
}

.ripple > span {
    width: 0px;
    height: 0px;
    opacity: 0.5;
    position: absolute;
    background: var(--white-cream-color);
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    z-index: -1;
}

@keyframes rippleAnimation {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0.5;
    }
    100% {
        width: 500px;
        height: 500px;
        opacity: .9;
    }
}

/*Responsive*/
@media screen and (max-width: 1200px) and (min-width: 576px) {
    .hero-section-image-container {
        height: 60dvh;
    }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .ai-section-static-parallax-image {
        height: 65%;
        /*width: 65%;*/
        background-image: url('../../assets/img/main-page-ai-section-image.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: -90% top !important;
        background-size: 100%;
    }

    .hero-section-small-text {
        font-size: calc(5px + 1vw);
    }
}

@media screen and (max-width: 992px) and (min-width: 576px) {
    .hero-section-small-text {
        font-size: calc(10px + 1vw);
    }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
    .ai-section-static-parallax-image {
        height: 65%;
        /*width: 65%;*/
        background-image: url('../../assets/img/main-page-ai-section-image.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: 0% top !important;
        background-size: 150%;
    }
}

@media screen and (max-width: 576px) {
    .hero-section-image-container {
        height: 50dvh;
    }

    .ai-section-static-parallax-image {
        height: 90%;
        /*width: 65%;*/
        background-image: url('../../assets/img/main-page-ai-section-image.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: 30% top !important;
        background-size: 200% !important;
    }

    .hero-section-small-text {
        font-size: calc(12px + 1vw);
    }
}
