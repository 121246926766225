.mode-description-up {
    bottom: 15vh;
}

/*Detailed post design*/
.single-post-main-image {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.single-post-title {
    font-size: calc(4em + 4px);
}

.move-description-up-single-post {
    bottom: 15vh;
}

@media screen and (max-width: 768px) {
    .mode-description-up {
        bottom: 5vh;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }

    .move-description-up-single-post {
        bottom: 0;
    }
}