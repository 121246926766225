.hero-title {
    font-size: 3.5em;
    container-name: sidebar;
}

.hero-text {
    font-size: calc(18px + 1vw)
}

.card-container {
    perspective: 1000px;
}

.card {
    border: none;
    box-shadow: 0 0 30px #c0c0c0;
    transform-style: preserve-3d;
    transition: transform .05s linear;
}